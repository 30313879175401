import React from 'react'
import styled from '@emotion/styled'
import { node, object, string } from 'prop-types'

// Import Components
import { MetaData } from '@jelly/components'
import { ResetCss } from '@jelly/ui'

// Styled Components via Emotion
const AppWrapper = styled.div`
	height: 100%;
	width: 100%;

	* {
		box-sizing: ${({ boxSizing = 'content-box' }) => boxSizing}; // For border-box across all child elements
	}

	${({ theme }) => theme.MQ.M} {
		background-color: ${({ backgroundColor }) => backgroundColor || 'none'};
	}
`
LayoutNoHero.propTypes = {
	boxSizing: string,
	backgroundColor: string,
	children: node,
	meta: object.isRequired,
}

/**
 * This is the Layout Component
 * Wrap any page in this "template" to display the global header and footer
 * this will also give any page access to Theming and the UserProvider context component
 */
function LayoutNoHero({ boxSizing, backgroundColor, children, meta }) {
	return (
		<AppWrapper className="inverse" boxSizing={boxSizing} backgroundColor={backgroundColor}>
			<MetaData meta={meta} />
			<ResetCss />
			{children}
		</AppWrapper>
	)
}

export default LayoutNoHero
