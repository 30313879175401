// external dependencies
import React from 'react'
import styled from '@emotion/styled'
import SearchProvider from './_components/SearchProvider'

// components
import { Footer, HeaderBanner } from '@jelly/components'
import LayoutNoHero from 'common/LayoutNoHero'
import SearchBody from './_components/SearchBody'

// styled components
const HeaderBannerWrap = styled.div`
	background: ${({ theme }) => theme.colors.lightGrey};
	border-bottom: ${({ theme }) => `thin solid ${theme.colors.navy}`};
	height: 100px;
	width: 100%;
`

function SearchPage() {
	return (
		<LayoutNoHero meta={{ canonical: `${process.env.GATSBY_SITE_HOST}/search` }}>
			<HeaderBannerWrap>
				<HeaderBanner />
			</HeaderBannerWrap>
			<SearchProvider>
				<SearchBody />
			</SearchProvider>
			<Footer />
		</LayoutNoHero>
	)
}

export default SearchPage
